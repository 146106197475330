<template>
  <div id="compare-documents-div">
    <div id="header-row" class="row">
      <VasionButton
        id="close-button"
        icon="VasionClear"
        classProp="secondary-grey"
        :makeHoverEffectSquare="true"
        @vasionButtonClicked="backToPreviousView"
      />
      <p id="compare-header">
        Compare Documents
      </p>
    </div>

    <div id="documents-area">
      <VasionCompareDocument
        id="firstDocument"
        :addRightBorder="true"
        :documentHeight="imageHeight"
        :documentWidth="imageWidth"
        :documentID="imageDataArray[0].DocumentId"
        :fileDetails="imageDataArray[0].FileDetail"
      />

      <VasionCompareDocument
        id="secondDocument"
        :addRightBorder="documentIDs.length === 3"
        :documentHeight="imageHeight"
        :documentWidth="imageWidth"
        :documentID="imageDataArray[1].DocumentId"
        :fileDetails="imageDataArray[1].FileDetail"
      />

      <VasionCompareDocument
        v-if="documentIDs.length === 3"
        id="thirdDocument"
        :documentHeight="imageHeight"
        :documentWidth="imageWidth"
        :documentID="imageDataArray[2].DocumentId"
        :fileDetails="imageDataArray[2].FileDetail"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheCompareDocuments',
  async beforeRouteLeave(to, from, next) {
    await this.$store.dispatch('common/hideNavbarAndHeader', false)
    next()
  },
  data() {
    return {
      imageDataArray: [],
      imageHeight: 650,
      imageWidth: 542,
      localBackRoute: '',
    }
  },
  computed: {
    documentIDs() { return this.$route.params.documentIDs },
  },
  async created() {
    this.localBackRoute = !this.$store.state.document.backRoute ? '/' : this.$store.state.document.backRoute

    if (this.documentIDs && this.documentIDs.length === 2) {
      await Promise.all([
        this.getDocument(this.documentIDs[0]),
        this.getDocument(this.documentIDs[1]),
      ])
    } else if (this.documentIDs && this.documentIDs.length === 3) {
      await Promise.all([
        this.getDocument(this.documentIDs[0]),
        this.getDocument(this.documentIDs[1]),
        this.getDocument(this.documentIDs[2]),
      ])
    } else {
      this.$router.push({ name: 'TheVasionHome' })
    }
  },
  methods: {
    async backToPreviousView() {
      await this.$store.dispatch('document/setBackRoute', '/')
      this.$router.push({ path: this.localBackRoute })
    },
    async getDocument(id) {
      const payload = {
        DocumentId: id,
        WorkflowStepID: null,
        IncludeFileInfo: true,
        IncludeIndexForm: false,
        IncludeNotes: false,
        IncludeWorkflow: false,
        IncludeLinks: false,
        IncludeVersions: false,
        CopyPDF: false,
      }
      const image = await this.$store.dispatch('document/getDocumentDetailsWithPayload', payload)
      this.imageDataArray.push(image)
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 12px;
    padding-bottom: 12px;
    margin: 0;
  }

  .top-bar {
    background: red !important;
  }

  #compare-documents-div {
    width: 100vw;
    height: 100vh;
  }

  #header-row {
    border-bottom: solid 1px $grey-100;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 1px 0 0 #e4e5e6;
    box-shadow: 0 1px 0 0 #e4e5e6;
  }

  #close-button {
    margin: 0 15px;
  }

  #compare-header {
    font-size: 18px;
    margin: 0;
    padding-top: 10px;
  }

  #documents-area {
    height: calc(100vh - 61px);
    overflow: auto;
    display: flex;
    flex-direction: row;
  }
</style>
